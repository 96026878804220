import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from 'utils/styleUtils';

const FullHeightHero = styled(Flex)`
  min-height: 600px;
  background-color: ${p => p.backgroundColor};
  background-image: url(${p => p.bgImageUrl});
  background-size: cover;
  color: ${p => p.color};
  position: relative;

  ${media.screenXL`
    height: calc(100vh - ${p => p.theme.headerHeightDesktop});
  `}

  > * {
    flex-grow: 1;
  }

  .h1 {
    ${media.screenXXL`
      font-size: 3.5vw;
    `}
  }
`;

FullHeightHero.defaultProps = {
  py: [5, 5, 10, `100px`],
  alignItems: `center`,
};

export default FullHeightHero;
