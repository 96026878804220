import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css, withTheme } from 'styled-components';
import { Box } from '@rebass/grid';
import renderHtml from 'react-render-html';

import { capitalizeFirstLetter } from 'utils/stringConversion';
import Heading from 'components/typography/heading';
import FauxLink from 'components/links/fauxLink';
import LinkColorChange from 'components/links/linkColorChange';
import Text from 'components/typography/text';
import BasicLink from 'components/links/basicLink';
import * as icons from 'components/icons/icons';

const Root = styled.div`
  display: block;

  > *:last-child {
    margin-bottom: 0;
  }
`;

const LearnMoreLink = styled(BasicLink)`
  a:hover &,
  a:focus &,
  button:hover &,
  button:focus & {
    color: ${p => p.theme.primaryDark};
  }
`;

const ImageWrapper = styled(Box)`
  position: relative;
  border-radius: ${p => p.theme.borderRadius};
  overflow: hidden;

  ${p => p.imageOverlay === false && css`
    border: 1px solid ${p.theme.greyLight};
  `}

  /* We only want to show the overlay if the imageOverlay prop is set to true */
  ${p => p.imageOverlay && css`
    &:after {
      content: '';
      background-color: ${p.theme.black};
      opacity: 0.4;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: ${p.theme.quickTransition};

      a:hover &,
      a:focus & {
        opacity: 0.2;
      }
    }
  `}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: ${p => p.theme.primary};
  border-radius: 500px;
  box-shadow: ${p => p.theme.boxShadow};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:1;
  height: 80px;
  width: 80px;
`;

const BasicTeaser = ({ title, subtitle, image, imageOverlay = true, icon, linkTo, theme, blank, as, ...rest }) => {
  let Icon;
  if (icon) {
    const iconNameFormatted = capitalizeFirstLetter(icon);
    Icon = icons[`${iconNameFormatted}Icon`];
  }

  let _LearnMoreLink = null;
  if (linkTo || as === `a`) {
    _LearnMoreLink = <LearnMoreLink as="span" color={theme.primary}>Learn more</LearnMoreLink>;
  }

  return (
    // If the 'linkTo' prop exists then the root element should be a link
    // React is intelligent enough to only pass the 'to' prop if it exists
    <Root as={(linkTo && FauxLink) || as} to={linkTo} {...rest}>
      {image && image.src && (
        <ImageWrapper mb={[3, 3, 5]} imageOverlay={imageOverlay}>
          <Img fluid={image.src.childImageSharp.fluid} alt={image.alt} />
          {icon && (
            <IconWrapper>
              <Icon size="m" fill={theme.white} />
            </IconWrapper>
          )}
        </ImageWrapper>
      )}

      <LinkColorChange>
        {/* We only want to show this icon if no image is provided via props */}
        {icon && !image && (
          <Box mb="1">
            <Icon size="s" />
          </Box>
        )}

        <Box mb={[2, 2, 3]}>
          <Heading rank="h3" style="h4" margin="0" color={theme.black}>{title}</Heading>
        </Box>
      </LinkColorChange>

      {subtitle && (
        <Box my={[2, 2, 3]}>
          <Text margin="0" color={theme.grey} weight="normal">{renderHtml(subtitle)}</Text>
        </Box>
      )}

      {/* Render the 'learn more' text if the basic teaser is either an internal link (includes the linkTo prop) or
      an external link (is rendering as an <a> tag) */}
      {_LearnMoreLink}
    </Root>
  );
};

BasicTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  imageOverlay: PropTypes.bool,
  icon: PropTypes.string,
  linkTo: PropTypes.string,
  theme: PropTypes.object.isRequired,
  blank: PropTypes.bool,
};

export default withTheme(BasicTeaser);

export const query = graphql`
  fragment BasicTeaserFragment on MarkdownRemark {
    frontmatter {
      path
      teaser {
        title
        subtitle
        icon
        image {
          src {
            childImageSharp {
              fluid(maxWidth: 590) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          alt
        }
      }
    }
  }
`;
