import styled from 'styled-components';

import FauxLink from 'components/links/fauxLink';

const LinkColorChange = styled.div`
  transition: ${p => p.theme.linkTransition};

  ${FauxLink}:hover &,
  ${FauxLink}:focus & {
    color: ${p => p.theme.primary};
    fill: ${p => p.theme.primary};
  }
`;

export default LinkColorChange;
