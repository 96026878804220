export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowercaseHyphen(text) {
  return text.split(` `).join(`-`).toLowerCase();
}

export function humaniseString(string) {
  const frags = string.split(/[-/_]/);
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(` `);
}
