import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CategoryHero from 'components/heros/categoryHero';
import Heading from 'components/typography/heading';
import GridRow from 'components/grid/gridRow';
import GridItem from 'components/grid/gridItem';
import BasicTeaser from 'components/teasers/basicTeaser';
import SignUpCta from 'components/ctas/signUpCta';

const Partners = ({ data }) => {
  const {
    markdownRemark: { frontmatter: {
      hero,
      partners,
    } },
    allPartners,
  } = data;

  return (
    <React.Fragment data={data}>
      <CategoryHero
        title={hero.title}
        subtitle={hero.subtitle}
        image={hero.image}
      />

      <GridRow
        title={<Heading rank="span" style="h2">{partners.title}</Heading>}
        gridWrapper="ul"
        gridItems={
          allPartners && allPartners.edges.map((partner) => {
            const { node: { frontmatter: {
              path,
              teaser: {
                title, subtitle, image,
              },
            } } } = partner;

            return (
              <GridItem key={path} as="li">
                <BasicTeaser
                  as="a"
                  href={path}
                  target="_blank"
                  title={title}
                  subtitle={subtitle}
                  image={image}
                  imageOverlay={false}
                />
              </GridItem>
            );
          })
        }
      />

      <SignUpCta />
    </React.Fragment>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...CategoryHeroFragment
      frontmatter {
        partners {
          title
          subtitle
        }
      }
    }
    allPartners: allMarkdownRemark(
      filter: {frontmatter: { type: {eq: "partner"}}},
      sort: {fields: [frontmatter___teaser___order], order: ASC},
    ) {
      edges {
        node {
          ...BasicTeaserFragment
        }
      }
    }
  }
`;

Partners.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Partners;
