import styled from 'styled-components';
import { Box } from '@rebass/grid';

const GridItem = styled(Box)``;

GridItem.defaultProps = {
  px: [3, 3, 5],
  mb: [5, 5, 10],
  width: [1, 1 / 2, 1 / 2, 1 / 4],
};

export default GridItem;
