import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { withTheme } from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import renderHTML from 'react-render-html';

import Container from 'components/grid/container';
import FullHeightHero from 'components/heros/fullHeightHero';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';

const Root = styled(FullHeightHero)`
  &:after {
    background-color: ${p => p.dark ? p.theme.black : p.theme.primary};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: ${p => p.dark ? 0.3 : 0.9};
    z-index: -1;
  }
`;

const BackgroundImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: -2;
`;

const CategoryHero = ({ title, subtitle, image, dark, theme, ...rest }) => {
  return (
    <Root dark={dark} {...rest}>
      {image && image.src && (
        <BackgroundImage fluid={image.src.childImageSharp.fluid} alt={image.alt} />
      )}
      <Container>
        <Flex flexWrap="wrap" alignItems="center">
          <Box width={[1, 1, 1, 6 / 12]}>
            <Heading rank="h1" style="h1" margin="0" color={theme.white}>{renderHTML(title)}</Heading>
            {subtitle && (
              <Box py={5}>
                <Text style="h4" margin="0" color={theme.white}>{renderHTML(subtitle)}</Text>
              </Box>
            )}
          </Box>
        </Flex>
      </Container>
    </Root>
  );
};

CategoryHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.object.isRequired,
  dark: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export default withTheme(CategoryHero);

export const query = graphql`
  fragment CategoryHeroFragment on MarkdownRemark {
    frontmatter {
      hero {
        title
        subtitle
        image {
          src {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          alt
        }
      }
    }
  }
`;
