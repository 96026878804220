import { Link } from 'gatsby';
import styled from 'styled-components';

const FauxLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-weight: inherit;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
`;

export default FauxLink;
