import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';

const Root = styled(Box)`
  text-align: ${p => p.textAlign || `center`};
`;

const GridWrapper = styled(Flex)`
  list-style: none;
`;

const HeadingWrapper = styled(Box)`
  > *:last-child {
    margin-bottom: 0;
  }
`;

const GridRow = ({ title, subtitle, gridWrapper, gridItems, cta, ...rest }) => {
  return (
    <Root {...rest}>
      <Container>
        <MaxWidth>
          {(title || subtitle) && (
            <HeadingWrapper mb={[7, 7, `100px`]}>
              {title}
              {subtitle && (
                <Box my={[3, 3, 5]}>
                  {subtitle}
                </Box>
              )}
            </HeadingWrapper>
          )}
        </MaxWidth>

        <GridWrapper as={gridWrapper} flexWrap="wrap" mx={[-3, -3, -5]} mt={title && [5, 5, `70px`]}>
          {gridItems}
        </GridWrapper>

        {cta && (
          <Box mt={[5, 5, `40px`]} mb={[5]}>{cta}</Box>
        )}
      </Container>
    </Root>
  );
};

GridRow.defaultProps = {
  pt: [10, 10, `100px`],
  pb: [5, 5, 10],
};

GridRow.propTypes = {
  title: PropTypes.object,
  subtitle: PropTypes.object,
  gridWrapper: PropTypes.string,
  gridItems: PropTypes.array.isRequired,
  cta: PropTypes.object,
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default GridRow;
